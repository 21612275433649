import React, { Component } from "react"

export class CustomDescription extends Component {
  render() {
    return (
      <div>
        <p
          style={{
            display: this.props.show,
            padding: `0 1rem`,
            fontSize: `0.9rem`,
          }}
        >
          {this.props.content}
        </p>
      </div>
    )
  }
}

export default CustomDescription
