import React from "react"
import { Link } from "gatsby"
import { Calendar } from "react-calendar"
import { graphql } from "gatsby"
import Button from "react-bootstrap/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ViewMore from "../images/vMore.png"
import Hide from "../images/hide.png"

import sideImageL from "../images/siderailimg.png"
import sideImageR from "../images/siderailimg.png"
import "../components/static/styles/calendar.css"
import "../components/static/styles/mainTimetable.css"
import CustomDescription from "../components/customDescription"

const divStyles = {
  backgroundColor: `antiquewhite`,
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  rowGap: `0.5rem`,
  justifyItems: `center`,
  padding: `1rem 0.5rem`,
  alignItems: `start`,
  alignContent: `start`,
  width: `100%`,
}

const classHolder = {
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  textAlign: `left`,
  padding: `0.3rem`,
  borderBottom: `2px solid #4CAF50`,
  color: `black`,
  lineHeight: `2rem`,
  margin: `0.8rem auto`,
}

const imgButtonStyles = {
  width: `2.2rem`,
  height: `auto`,
}

class CalendarComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      date: new Date(),
      viewMoreButton: "View More",
      viewMoreButtonImg: ViewMore,
      displaySegmentId: [{ id: "", display: "none" }],
      prodCode: "",
    }

    this.showMoreButton = this.showMoreButton.bind(this)
  }

  datePlusTwoMonths = date => {
    const noOfMonths = 2
    let currentDate = new Date()
    const newDate = new Date(
      currentDate.setMonth(currentDate.getMonth() + noOfMonths)
    )
    // console.log(newDate)

    return newDate
  }

  onChange = date => {
    this.setState({ date })
    // console.log(date)
  }

  dateCheckerButton(date, time) {
    const startInstanceString = date + "," + time
    // console.log("bookingStartString", startInstanceString)

    const startInstance = new Date(startInstanceString).getTime()
    // console.log("bookingStartInstance", startInstance)

    const currentTime = new Date().getTime()
    // console.log("currentServerInstance", currentTime)

    if (startInstance < currentTime) {
      // console.log("returning true: bookingStart < currentTime")
      return true
    }

    return false
  }

  dateCheckerTimetable(startDate, endDate, time, type) {
    if (startDate === "") {
      console.log("Start date is blank")
      return
    } else {
      const startInstanceString = startDate + "," + time
      // console.log("Booking StartInstance string", startInstanceString)
      const startInstanceTime = new Date(startInstanceString).getTime()
      // console.log("Booking Start Instance", startInstanceTime)
      const currentInstanceTime = new Date(this.state.date).getTime()
      // console.log("Current Time Instance", currentInstanceTime)

      if (startInstanceTime > currentInstanceTime) {
        return false
      }
    }

    if (endDate === "") {
      // console.log("no end date - TRUE dateCheckerTimetable")
      return true
    }

    const endInstanceString = endDate + "," + time

    if (type === "oneOff") {
      const endInstanceDay = new Date(endInstanceString).getDate()
      const currentInstanceDay = new Date(this.state.date).getDate()
      // console.log("type OneOff", endInstanceDay, currentInstanceDay)

      if (endInstanceDay === currentInstanceDay) {
        return true
      } else {
        return false
      }
    }

    const endInstance = new Date(endInstanceString).getTime()
    const currentTime = new Date().getTime()

    if (endInstance > currentTime) {
      // console.log("All good from Ze dateCheckerTimetable")
      return true
    }
  }

  dayChecker(days) {
    const arr = days

    const currentNumericalDay = new Date(this.state.date).getDay()

    let currentStringDay = ""

    switch (currentNumericalDay) {
      case 0:
        currentStringDay = "Sun"
        break

      case 1:
        currentStringDay = "Mon"
        break

      case 2:
        currentStringDay = "Tue"
        break

      case 3:
        currentStringDay = "Wed"
        break

      case 4:
        currentStringDay = "Thu"
        break

      case 5:
        currentStringDay = "Fri"
        break

      case 6:
        currentStringDay = "Sat"
        break

      default:
        break
    }

    const itemPosition = arr.indexOf(currentStringDay)
    // console.log("Item Position ", itemPosition)

    if (itemPosition < 0) {
      return false
    }

    // console.log("All good from the DayChecker")
    return true
  }

  compareFunc(a, b) {
    if (a.startTime > b.startTime) {
      return 1
    } else if (a.startTime < b.startTime) {
      return -1
    } else {
      return 0
    }
  }

  showMoreButton(eventId) {
    let displaySegmentIdArr = []

    let displayToggle = ""

    if (this.state.displaySegmentId[0].id === eventId) {
      displayToggle = this.state.displaySegmentId[0].display

      if (displayToggle === "unset") {
        displayToggle = "none"
      } else if (displayToggle === "none") {
        displayToggle = "unset"
      }

      displaySegmentIdArr = [{ id: eventId, display: displayToggle }]
    } else {
      displaySegmentIdArr = [{ id: eventId, display: "unset" }]
    }

    this.setState(
      {
        viewMoreButton:
          this.state.viewMoreButton === "View More" ? "Hide" : "View More",
        viewMoreButtonImg:
          this.state.viewMoreButtonImg === ViewMore ? Hide : ViewMore,
        displaySegmentId: displaySegmentIdArr,
      },
      function() {
        // console.log("displaySegmentId set to", displaySegmentIdArr)
      }
    )
  }

  checkDisplayStatusDescription(eventId) {
    if (eventId === this.state.displaySegmentId[0].id) {
      const displayCode = this.state.displaySegmentId[0].display

      // console.log(displayCode, "checkDisplayStatusDescription display")

      return displayCode
    } else {
      return "none"
    }
  }

  checkDisplayStatusButton(eventId) {
    if (eventId === this.state.displaySegmentId[0].id) {
      const displayCode = this.state.displaySegmentId[0].display

      // console.log(displayCode, "checkDisplayStatusButton display")
      if (displayCode === "unset") {
        return "Hide"
      } else {
        return "View More"
      }
    } else {
      return "View More"
    }
  }

  checkDisplayStatusImg(eventId) {
    if (eventId === this.state.displaySegmentId[0].id) {
      const displayCode = this.state.displaySegmentId[0].display

      // console.log(displayCode, "checkDisplayStatusButton display")
      if (displayCode === "unset") {
        return Hide
      } else {
        return ViewMore
      }
    } else {
      return ViewMore
    }
  }

  logMessages(number) {
    const arrIndex = number

    switch (number) {
      case 1:
        this.setState({
          prodCode: "sku_FkjyqVPNE4TaIC",
        })
        console.log("Booking for", arrIndex)
        break
      case 2:
        this.setState({
          prodCode: "sku_FkjyqVPNE4TaIC",
        })
        console.log("Booking for", arrIndex)
        break
      case 3:
        this.setState({
          prodCode: "sku_FkjyqVPNE4TaIC",
        })
        console.log("Booking for", arrIndex)
        break

      default:
        break
    }
  }

  render() {
    const { data } = this.props
    // console.log(data.dataJson.events)

    let origDataArr = data.dataJson.events
    // console.log(origDataArr)

    let sortedDataArr = origDataArr.sort(this.compareFunc)
    // console.log(sortedDataArr)

    let listEvents = sortedDataArr.map(event => {
      if (
        this.dateCheckerTimetable(
          event.startDate,
          event.endDate,
          event.startTime,
          event.type
        ) &&
        this.dayChecker(event.days)
      )
        return (
          <div
            key={event.id}
            style={classHolder}
            onClick={() => {
              this.showMoreButton(event.id)
            }}
            className="timetable_page_classHolder"
          >
            <span style={{ marginLeft: `0.5rem` }}>
              Date: {new Date(this.state.date).toDateString()}
            </span>
            <span style={{ marginLeft: `0.5rem` }}>
              {" "}
              {event.startTime} - {event.endTime}
            </span>
            <span
              style={{
                marginLeft: `0.5rem`,
                color: `grey`,
                fontSize: `1rem`,
                fontWeight: `bold`,
              }}
            >
              {" "}
              {event.title}
            </span>
            <span style={{ marginLeft: `0.5rem`, color: `#504e4e` }}>
              {" "}
              {event.teacher}
            </span>
            <span key={event.id} style={{ margin: `0.5rem auto` }}>
              <img
                src={this.checkDisplayStatusImg(event.id)}
                alt="View More"
                style={imgButtonStyles}
              />{" "}
              <span style={{ marginLeft: `0.3rem` }}>
                {this.checkDisplayStatusButton(event.id)}
              </span>{" "}
            </span>
            <CustomDescription
              content={event.description}
              show={this.checkDisplayStatusDescription(event.id)}
            />
            {!this.dateCheckerButton(this.state.date, event.startTime) && (
              <Link
                to="/checkout"
                state={{ productCode: event.prodCode, date: this.state.date }}
                style={{ display: `grid` }}
              >
                <Button
                  style={{
                    marginBottom: `0.0rem`,
                    backgroundColor: `#FF5722`,
                    border: `none`,
                    fontWeight: `600`,
                    height: `2.4rem`,
                    margin: `0.5rem auto`,
                    width: `85%`,
                    zIndex: `1`,
                  }}
                  onClick={() => {
                    this.logMessages(event.id)
                  }}
                  id={event.topic}
                >
                  BOOK NOW
                </Button>
              </Link>
            )}
          </div>
        )
      return null
    })

    const checkForNull = item => {
      return item !== null
    }

    let checkForResult = listEvents.filter(checkForNull)

    if (checkForResult.length === 0) {
      listEvents = (
        <p style={classHolder}>
          Sorry, no more classes today. <br />
          Please check other dates, for more :)
          <br />
        </p>
      )
    }
    return (
      <Layout>
        <SEO title="Timetable" />
        <div className="mainTimetable">
          <img
            src={sideImageL}
            alt="sidebanner"
            className="sideImageLTimetable"
          />
          <div style={divStyles} className="divStyles__timetable">
            <h3 style={{ fontWeight: `bold` }}>TIMETABLE</h3>
            <Calendar
              onChange={this.onChange}
              value={this.state.date}
              minDate={new Date()}
              maxDate={this.datePlusTwoMonths()}
            />
            <div>{listEvents}</div>
          </div>
          <img
            src={sideImageR}
            alt="sidebanner"
            className="sideImageRTimetable"
          />
        </div>
      </Layout>
    )
  }
}

export default CalendarComponent

export const query = graphql`
  query {
    dataJson {
      events {
        id
        teacher
        title
        endDate
        endTime
        startDate
        startTime
        days
        type
        description
        prodCode
      }
    }
  }
`
